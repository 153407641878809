<template>
    <div class="wishMatch">
        <headComp></headComp>
        <breadComp></breadComp>

        <div class="centerWrapper">
            <div class="chooseCombo">
                <div :class="{'item':1,'active':chosen==item.atWillPackageId}" v-for="(item,i) in wishPackageList" :key="i" @click="clickPackage(item)">{{item.title}}</div>
            </div>
            <div class="choseCombine">
                <p>选择商品组合</p>
                <div class="list">
                    <div class="inner">
                        <div :class="{'combineItem':1,'active':chosen2==item.atWillClassilyId}" v-for="(item,i) in wishClassifyList" :key="i" @click="clickClassify(item)">
                            {{item.classilyName}}
                            <span v-show="item.chosenGoodsObj[0].goodsId">(已搭配)</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="combine" v-if="false">
                    <p>选择商品组合</p>
                    <div :class="{'combineItem':1,'active':chosen2==item.atWillClassilyId}" v-for="(item,i) in wishClassifyList" :key="i" @click="clickClassify(item)">
                        {{item.classilyName}}
                        <!-- <img v-show="chosen2==item.atWillClassilyId" src="../assets/imgs/effect6.png"/> -->
                        <!-- <img v-show="item.chosenGoodsObj.goodsId" src="../assets/imgs/effect6.png"/> -->
                        <img class="corner" v-show="item.chosenGoodsObj[0].goodsId" src="../assets/imgs/effect27.png"/>
                        <div class="chosedTxt" v-show="item.chosenGoodsObj[0].goodsId">已搭配</div>
                    </div>
                </div>
                <div class="choice">
                    <div class="top">
                        <span>{{chosen2Item.classilyName}}</span>
                        <div class="num">
                            精选<span>{{chosen2Item.goodsNumber}}</span>款搭配任你选择
                        </div>
                    </div>
                    <div class="list">
                        <div class="item" v-for="(item,i) in wishGoodsList" :key="i">
                            <!-- <img src="../assets/imgs/place31.png"/> -->
                            <div class="coverImg" @click="clickGoods(item)">
                                <!-- <img class="cover" :src="item.cover?item.cover:(item.mainImages?item.mainImages.split(',')[0]:'')"/> -->
                                <img class="cover" :src="item.cover"/>
                                <!-- <img class="cover" src="../assets/imgs/effect25.png"/> -->
                                <!-- <img class="chosedImg" v-show="chosen2Item.chosenGoodsObj.findIndex(it=>it.goodsId==item.goodsId)!=-1" src="../assets/imgs/effect28.png"/>                                 -->
                            </div>
                            <div class="price" @click="clickGoods(item)">
                                <span>￥</span>{{item.ourShopPrice}}
                            </div>
                            <div class="name" @click="clickGoods(item)">{{item.name}}</div>
                            <div class="tags">
                                <span v-show="item.goodsTypeName">{{item.goodsTypeName}}</span>
                                <span v-show="item.brandName">{{item.brandName}}</span>
                            </div>
                            <!-- <div class="chooseBox" :style="chosen2Item.chosenGoodsObj.goodsId==item.goodsId?{'border-color':'transparent'}:{}">
                                <img v-show="chosen2Item.chosenGoodsObj.goodsId==item.goodsId" class="checkedImg" src="../assets/imgs/effect6.png"/>
                            </div> -->

                            <div class="chosenMark" @click.stop="clickChooseGoods(item)">
                                <i class="el-icon-circle-plus noChosen" v-show="chosen2Item.chosenGoodsObj.findIndex(it=>it.goodsId==item.goodsId)==-1"></i>
                                <i class="el-icon-success chosen" v-show="chosen2Item.chosenGoodsObj.findIndex(it=>it.goodsId==item.goodsId)!=-1"></i>
                            </div>
                        </div>

                        <div class="empty" v-show="wishGoodsList.length==0">
                            <img src="../assets/imgs/goodsEmpty.png" />
                            <div>店家正在努力上新中~</div>
                        </div>
                    </div>
                </div>
                <div class="combo">
                    <div class="comboName">{{chosenItem.title}}</div>
                    <div class="vessel">
                        <div class="region" v-for="(item,i) in handleSpaceArr" :key="i">
                            <div class="name">{{item[0].atWillSpaceName}}</div>
                            <div class="kind" v-for="(subItem,j) in item" :key="j">
                                <span class="kindName" @click="clickRightChoose(subItem)">{{subItem.classilyName}}</span>
                                <div class="val">
                                    <div class="valItem" v-for="(chosenGoods,k) in subItem.chosenGoodsObj" :key="chosenGoods.goodsId">
                                        <div class="place" v-show="!chosenGoods.goodsId" @click="clickRightChoose(subItem)">
                                            <div class="placePic">
                                                <img src="../assets/imgs/effect7.png"/>
                                            </div>
                                            <div class="text">请选择商品</div>
                                        </div>
                                        <!-- <div class="goods" v-show="chosenGoodsObj.goodsId">
                                            <img src="../assets/imgs/place32.png"/>
                                            <div class="right">
                                                <div class="goodsName">纾康  现代简约  科技布  （1+3+左贵妃...</div>
                                                <div class="price">￥5799.00</div>
                                                <el-input-number v-model="num" @change="handleChange" :min="1" :max="10" label="描述文字"></el-input-number>
                                            </div>
                                        </div> -->
                                        <div class="goods" v-show="chosenGoods.goodsId">
                                            <!-- <img src="../assets/imgs/place32.png"/> -->
                                            <img :src="chosenGoods.cover"/>
                                            <div class="right">
                                                <div class="goodsName">{{chosenGoods.name}}</div>
                                                <div class="price">￥{{chosenGoods.ourShopPrice}}</div>
                                                <el-input-number v-model="chosenGoods._goodsNumber" @change="()=>handleChange(subItem,chosenGoods,k)" :min="0" :max="10" label="选择数量"></el-input-number>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="textLine">随心配—{{chosenItem.title}}</div>
                        <div class="chosen">已选<span>{{chosenGoodsNum}}</span>件，共{{chosenItem.goodsNumber}}件套</div>
                        <div class="btn">
                            <el-button class="shopCart" type="danger" @click="toClear">立即结算</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <loginBoxComp @close="showLoginBox=false" v-show="showLoginBox"></loginBoxComp>
        <footerComp></footerComp>
    </div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
import breadComp from "@/components/breadComp.vue"
import loginBoxComp from "@/components/loginBoxComp"
import {getAtWillPackageList,getAtWillClassifyList,getAtWillGoodsList} from "@/axios/api"
export default {
    components: {
        headComp,footerComp,breadComp,loginBoxComp
    },
    data(){
        return{
            chosen:"",
            chosenItem:{},
            chosen2:"",
            chosen2Item:{},
            num:1,
            wishPackageList:[],//随心配列表
            wishClassifyList:[],//随心配分类列表
            wishGoodsList:[],//随心配详细(商品列表)
            handleSpaceArr:[],//按空间类型分组
            chosenGoodsNum:0,
            showLoginBox:false,
            fromPage:"",
        }
    },
    methods: {
        toClear(){
            if(!localStorage.AOGEYA_loginInfo){
                // this.$commonFun.confirmBox(this,"未登录，是否前往登录？",'/login');
                this.showLoginBox=true;
                return
            }

            if(this.chosenGoodsNum==0){
                // this.$message("请至少选择一件商品")
                this.$commonFun.wrongTip(this,'未选择商品')
                return
            }

            var arr=[]
            this.handleSpaceArr.forEach(item=>{
                item.forEach(subItem=>{
                    if(subItem.chosenGoodsObj[0].goodsId){
                        // subItem.chosenGoodsObj.calcTotalPrice=subItem.chosenGoodsObj._goodsNumber*parseFloat(subItem.chosenGoodsObj.ourShopPrice)
                        // arr.push(subItem.chosenGoodsObj)

                        subItem.chosenGoodsObj.forEach(goods=>{
                            goods.calcTotalPrice=goods._goodsNumber*parseFloat(goods.ourShopPrice)
                            arr.push(goods)
                        })
                    }
                })
            })
            sessionStorage.wishChosenGoodsList=JSON.stringify(arr)
            this.$router.push({
                name:"wishMatch_clear",
                params:{comboName:this.chosenItem.title}
            })
        },
        handleChange(subItem,chosenGoods,k){
            console.log(chosenGoods._goodsNumber)
            if(chosenGoods._goodsNumber==0){
                subItem.chosenGoodsObj.splice(k,1);
                if(subItem.chosenGoodsObj.length==0){
                    subItem.chosenGoodsObj.push({})
                }
            }
        },
        // 获取随心配列表
        gainWishList(){
            getAtWillPackageList().then(res=>{
                console.log("随心配列表",res);
                if(res.code==200){
                    this.wishPackageList=res.data;
                    if(res.data.length>0){
                        this.clickPackage(res.data[0])
                    }
                }
            })
        },
        // 点击随心配列表
        clickPackage(item){
            // if(this.chosen==item.atWillPackageId){
            //     return
            // }
            this.chosen=item.atWillPackageId;
            this.chosenItem=item;

            getAtWillClassifyList({atWillPackageId:item.atWillPackageId}).then(res=>{
                console.log("随心配套餐分类",res);
                if(res.code==200){
                    this.wishClassifyList=res.data;
                    var spaceIdArr=[],spaceArr=[];
                    res.data.forEach(item=>{
                        if(!spaceIdArr.includes(item.atWillSpaceId)){
                            spaceIdArr.push(item.atWillSpaceId)
                        }
                    })
                    spaceIdArr.forEach((id,i)=>{
                        spaceArr[i]=[];
                        res.data.forEach(subItem=>{
                            this.$set(subItem,'chosenGoodsObj',[{}])
                            // subItem.chosenGoodsObj={}
                            if(subItem.atWillSpaceId==id){
                                spaceArr[i].push(subItem)
                            }
                        })
                    })
                    console.log(spaceArr)
                    this.handleSpaceArr=spaceArr;

                    if(this.fromPage=="/furnitureDetail"&&sessionStorage.AOGEYA_wishClassifyList){
                        var sessList=JSON.parse(sessionStorage.AOGEYA_wishClassifyList);
                        for(var i=0;i<res.data.length;i++){
                            for(var j=0;j<sessList.length;j++){
                                if(res.data[i].atWillClassilyId==sessList[j].atWillClassilyId){
                                    res.data[i].chosenGoodsObj=sessList[j].chosenGoodsObj
                                    break
                                }
                            }
                        }
                    }else{
                        sessionStorage.removeItem("AOGEYA_wishClassifyList")
                    }
                    
                    if(res.data.length>0){
                        this.clickClassify(res.data[0])
                    }
                }
            })
        },
        // 点击分类
        clickClassify(item){
            if(this.chosen2==item.atWillClassilyId){
                return
            }
            this.chosen2Item=item;
            this.chosen2=item.atWillClassilyId;

            getAtWillGoodsList({atWillClassilyId:item.atWillClassilyId}).then(res=>{
                console.log("随心配详细信息",res);
                if(res.code==200){
                    this.wishGoodsList=res.data;
                }
            })
        },
        // 点击商品
        clickGoods(item){
            // console.log(item)
            for(var i=0;i<this.wishClassifyList.length;i++){
                if(this.wishClassifyList[i].chosenGoodsObj[0].goodsId){
                    sessionStorage.AOGEYA_wishClassifyList=JSON.stringify(this.wishClassifyList)
                    break
                }
            }
            // this.$router.push({
            //     path:"/furnitureDetail",
            //     query:{goodsId:item.goodsId}
            // })
            
            var href=location.href.substring(0,location.href.indexOf("wishMatch")-1);
            // console.log(href)
            window.open(href+'/furnitureDetail?goodsId='+item.goodsId,"__blank")
        },
        // 点击选择商品
        clickChooseGoods(item){
            // console.log(item)
            // console.log(this.chosen2Item)
            this.$set(item,'_goodsNumber',1)
            this.handleSpaceArr.forEach(arr=>{
                if(arr[0]&&arr[0].atWillSpaceId==this.chosen2Item.atWillSpaceId){
                    var index=arr.findIndex(obj=>obj.atWillClassilyId==this.chosen2Item.atWillClassilyId);
                    // console.log(index)
                    // arr[index].chosenGoodsObj=item
                    if(arr[index].chosenGoodsObj[0].goodsId){
                        var ti=arr[index].chosenGoodsObj.findIndex(obj=>obj.goodsId==item.goodsId);
                        if(ti==-1){
                            arr[index].chosenGoodsObj.push(item)
                        }else{
                            arr[index].chosenGoodsObj.splice(ti,1);
                            if(arr[index].chosenGoodsObj.length==0){
                                arr[index].chosenGoodsObj.push({})
                            }
                        }
                    }else{
                        this.$set(arr[index].chosenGoodsObj,0,item);
                    }
                    
                }
            })
            // this.chosen2Item.chosenGoodsObj=item

            this.calcNum();
        },
        // 计算选择数
        calcNum(){
            var num=0
            this.handleSpaceArr.forEach(item=>{
                item.forEach(subItem=>{
                    // if(subItem.chosenGoodsObj.goodsId){
                    //     num++
                    // }
                    subItem.chosenGoodsObj.forEach(goods=>{
                        if(goods.goodsId){
                            num++
                        }
                    })
                })
            })
            this.chosenGoodsNum=num
        },
        // 点击右侧选择时
        clickRightChoose(subItem){
            // console.log(subItem);
            // this.chosen2Item=subItem;
            // this.chosen2=subItem.atWillClassilyId;
            this.clickClassify(subItem)
        }
    },
    created() {
        this.gainWishList();
    },
    beforeRouteEnter (to, from, next) {
        next(vm=>vm.fromPage=from.path)
    }
}
</script>

<style lang="less" scoped>
.wishMatch{
    .chooseCombo{
        margin-top: 50px;
        display: flex;
        align-items: center;
        .item{
            height: 60px;
            padding: 0 27px;
            display: flex;
            align-items: center;
            border-radius: 6px;
            margin-right: 7px;
            border: 1px solid rgba(229, 229, 229, 1);
            font-size: 18px;
            font-weight: 400;
            color: rgba(128, 128, 128, 1);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            &:last-child{
                margin-right: 0;
            }
            &.active{
                border-color: rgba(37, 126, 93, 1);
                background: rgba(37, 126, 93, 1);
                color: rgba(255, 255, 255, 1)
            }
        }
    }
    .choseCombine{
        margin-top: 20px;
        &>p{
            margin: 0;
            font-size: 16px;
            font-weight: 700;
            color: rgba(0, 0, 0, 1);
        }
        .list{
            margin-top: 6px;
            overflow-x: auto;
            *::-webkit-scrollbar{
                height: 0;
            }
            .inner{
                width: fit-content;
                display: flex;
                align-items: center;
                .combineItem{
                    min-width: 102px;
                    padding: 0 20px;
                    height: 34px;
                    border-radius: 17px;
                    font-size: 15px;
                    font-weight: 700;
                    color: rgba(51, 51, 51, 1);
                    margin-right: 44px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:last-child{
                        margin-right: 0;
                    }
                    &.active{
                        background: rgba(168, 124, 76, 1);
                        color: #fff;
                        &>span{
                            color: rgba(255, 255, 255, 1)
                        }
                    }
                    &>span{
                        margin-left: 6px;
                        font-size: 13px;
                        font-weight: 500;
                        letter-spacing: 0px;
                        line-height: 18.2px;
                        // color: rgba(255, 255, 255, 1);
                        color: rgba(168, 124, 76, 1)
                    }
                }
            }
        }
    }
    .container{
        margin-top: 45px;
        display: flex;
        align-items: flex-start;
        .combine{
            width: 241px;
            p{
                padding: 0 10px;
                font-size: 13px;
                font-weight: 700;
                color: #333;
                margin-bottom: 10px;
            }
            .combineItem{
                height: 54px;
                padding: 0 63px;
                background: rgba(247, 247, 247, 1);
                font-size: 15px;
                font-weight: 700;
                color: #333;
                display: flex;
                align-items: center;
                border-bottom: 1px solid rgba(222, 222, 222, 1);
                cursor: pointer;
                position: relative;
                &:last-child{
                    border-bottom: none;
                }
                &.active{
                    background: rgba(158, 110, 62, 1);
                    border-color: rgba(158, 110, 62, 1);
                    color: rgba(255, 255, 255, 1);
                }
                img{
                    width: 26px;
                    height: 26px;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                img.corner{
                    width: 22px;
                    height: 22px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    transform: translateY(0);
                }
                .chosedTxt{
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 13px;
                    font-weight: 500;
                    color: rgba(0, 199, 106, 1);
                }
            }
        }
        .choice{
            // width: 969px;
            // width: 900px;
            // margin-left: 30px;
            width: 1171px;
            .top{
                padding: 10px 5px;
                font-size: 17px;
                font-weight: 700;
                color: #333;
                display: flex;
                align-items: center;
                border-bottom: 1px solid rgba(229, 229, 229, 1);
                .num{
                    margin-left: 10px;
                    font-size: 12px;
                    font-weight: 700;
                    color: #333;
                    &>span{
                        color: rgba(224, 179, 108, 1);
                        margin: 0 2px;
                    }
                }
            }
            .list{
                margin-top: 33px;
                // padding: 0 15px;
                display: flex;
                flex-wrap: wrap;
                .item{
                    width: 267px;
                    // margin-right: 63px;
                    // margin-right: 34px;
                    margin: 0 10px;
                    margin-bottom: 24px;
                    cursor: pointer;
                    position: relative;
                    background: rgba(245, 245, 245, 1);
                    &:nth-child(3n){
                        margin-right: 0
                    }
                    &>.coverImg{
                        width: 267px;
                        height: 195px;
                        position: relative;
                        overflow: hidden;
                        border-radius: 6px 6px 0 0;
                        &>img.cover{
                            width: 267px;
                            height: 195px;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%,-50%);
                            transition: all 0.3s;
                            border-radius: 6px 6px 0 0;
                        }
                        .chosedImg{
                            width: 93px;
                            height: 93px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                        }
                    }
                    .price{
                        margin-top: 16px;
                        font-size: 14px;
                        font-weight: 700;
                        color: rgba(212, 48, 48, 1);
                        padding: 0 5px;
                        &>span{
                            font-size: 13px;
                        }
                    }
                    .name{
                        margin-top: 6px;
                        font-size: 11px;
                        font-weight: 700;
                        line-height: 15.4px;
                        height: 30px;
                        color: #333;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        padding: 0 5px;
                    }
                    .tags{
                        margin-top: 10px;
                        margin-bottom: 10px;
                        display: flex;
                        align-items: center;
                        padding: 0 5px;
                        span{
                            height: 19px;
                            padding: 0 7px;
                            border-radius: 4px;
                            border: 1px solid rgba(184, 125, 75, 1);
                            font-size: 11px;
                            font-weight: 700;
                            color: rgba(184, 125, 75, 1);
                            margin-right: 7px;
                            display: flex;
                            align-items: center;
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                    .chooseBox{
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                        border: 1px solid #eee;
                        img.checkedImg{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    &:hover{
                        .coverImg{
                            img.cover{
                                width: 287px;
                                height: 215px;
                            }
                        }
                    }

                    .chosenMark{
                        position: absolute;
                        right: 14px;
                        bottom: 8px;
                        font-size: 34px;
                        display: flex;
                        align-items: flex-end;
                        i.noChosen{
                            color: rgba(209, 209, 209, 1);
                        }
                        i.chosen{
                            color: rgba(168, 124, 76, 1);
                        }
                    }
                }

                .empty{
                    width: 100%;
                    padding: 50px 0;
                    text-align: center;
                    &>img{
                        width: 500px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .combo{
            flex: 1;
            margin-left: 30px;
            .comboName{
                height: 72px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(37, 126, 93, 1);
                font-size: 18px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
            }
            .vessel{
                border: 1px solid rgba(222, 222, 222, 1);
                padding: 20px 13px;
                .region{
                    margin-bottom: 30px;
                    .name{
                        font-size: 14px;
                        font-weight: 700;
                        color: #333;
                        padding-bottom: 10px;
                        border-bottom: 1px solid rgba(229, 229, 229, 1);
                        margin-bottom: 18px;
                    }
                    .kind{
                        margin-top: 20px;
                        display: flex;
                        align-items: center;
                        .kindName{
                            font-size: 13px;
                            font-weight: 700;
                            color: rgba(56, 56, 56, 1);
                            margin-right: 18px;
                            width: 60px;
                            height: 57px;
                            line-height: 57px;
                            align-self: flex-start;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            cursor: pointer;
                        }
                        .val{
                            flex: 1;
                            .valItem{
                                margin-top: 10px;
                                &:first-child{
                                    margin-top: 0;
                                }
                            }
                            .place{
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                .placePic{
                                    width: 76px;
                                    height: 57px;
                                    border: 1px solid rgba(217, 217, 217, 1);
                                    box-sizing: border-box;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    cursor: pointer;
                                    img{
                                        width: 60%;
                                    }
                                }
                                .text{
                                    margin-left: 9px;
                                    font-size: 11px;
                                    font-weight: 500;
                                    color: rgba(106, 115, 166, 1);

                                }
                            }
                            .goods{
                                display: flex;
                                align-items: flex-start;
                                img{
                                    width: 76px;
                                    height: 57px;
                                    cursor: pointer;
                                }
                                .right{
                                    margin-left: 9px;
                                    .goodsName{
                                        font-size: 10px;
                                        font-weight: 700;
                                        line-height: 14px;
                                        color: #333;
                                        overflow: hidden;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                    }
                                    .price{
                                        margin-top: 3px;
                                        font-size: 10px;
                                        font-weight: 700;
                                        color: rgba(212, 48, 48, 1);
                                    }
                                    .el-input-number{
                                        margin-top: 10px;
                                        width: 70px;
                                        height: 17px;
                                        line-height: 17px;
                                        border: none;
                                        /deep/span{
                                            width: 20px;
                                            background: #fff;
                                            border: none;
                                            height: 17px;
                                            line-height: 17px;
                                            top: 0;
                                            &.el-input-number__decrease{
                                                left: 0;
                                            }
                                            &.el-input-number__increase{
                                                right: 0;
                                            }
                                        }
                                        /deep/.el-input{
                                            // width: 70px;
                                            height: 17px;
                                            .el-input__inner{
                                                border: none;
                                                padding-left: 20px;
                                                padding-right: 20px;
                                                background: rgba(204, 204, 204, 0.2);
                                                height: 17px;
                                                line-height: 17px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .textLine{
                    margin-top: 30px;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 700;
                    color: #333;
                }
                .chosen{
                    text-align: center;
                    margin-top: 8px;
                    font-size: 10px;
                    font-weight: 700;
                    color: rgba(102, 102, 102, 1);
                    &>span{
                        color: rgba(212, 48, 48, 1);
                    }
                }
                .btn{
                    text-align: center;
                    margin-top: 9px;
                    margin-bottom: 20px;
                    .shopCart{
                        height: 39px;
                        width: 179px;
                        border-radius: 0;
                        font-size: 15px;
                        font-weight: 700;
                        color: rgba(255, 255, 255, 1);
                        background: rgba(200, 64, 57, 1);
                        margin-bottom: -15px;
                    }
                }
            }
        }
    }
}
</style>